// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-sm {
  font-size: 0.8rem;
}

/* .modal-body- {
  height: 100px;
}

.modal-footer {
  height: 25px;
  padding: 0px;
}

.modal-header {
  height: 25px;
  padding: 0px;
} */
`, "",{"version":3,"sources":["webpack://./src/styles/Forgot.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;;;;;;;;;;;;GAYG","sourcesContent":[".btn-sm {\n  font-size: 0.8rem;\n}\n\n/* .modal-body- {\n  height: 100px;\n}\n\n.modal-footer {\n  height: 25px;\n  padding: 0px;\n}\n\n.modal-header {\n  height: 25px;\n  padding: 0px;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
