// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gold {
  background: linear-gradient(
    to right,
    #bf953f,
    #fcf6ba,
    #b38728,
    #fbf5b7,
    #aa771c
  );
}

.silver {
  background: linear-gradient(
    45deg,
    #999 5%,
    #fff 10%,
    #ccc 30%,
    #ddd 50%,
    #ccc 70%,
    #fff 80%,
    #999 95%
  );
}

.black {
  background: linear-gradient(
    to right,
    #0f0f0f 10%,
    #2e2d2d,
    #5f5e5e,
    #b2b0b0,
    #212121,
    #090909
  );
}

.opacity {
  background-color: transparent;
  opacity: 80%;
}

.gradient-text {
  background: linear-gradient(
    45deg,
    #999 5%,
    #fff 10%,
    #ccc 30%,
    #ddd 50%,
    #ccc 70%,
    #fff 80%,
    #999 95%
  );
  background-clip: text;
  color: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/styles/ColorPalette.css"],"names":[],"mappings":"AAAA;EACE;;;;;;;GAOC;AACH;;AAEA;EACE;;;;;;;;;GASC;AACH;;AAEA;EACE;;;;;;;;GAQC;AACH;;AAEA;EACE,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE;;;;;;;;;GASC;EACD,qBAAqB;EACrB,kBAAkB;AACpB","sourcesContent":[".gold {\n  background: linear-gradient(\n    to right,\n    #bf953f,\n    #fcf6ba,\n    #b38728,\n    #fbf5b7,\n    #aa771c\n  );\n}\n\n.silver {\n  background: linear-gradient(\n    45deg,\n    #999 5%,\n    #fff 10%,\n    #ccc 30%,\n    #ddd 50%,\n    #ccc 70%,\n    #fff 80%,\n    #999 95%\n  );\n}\n\n.black {\n  background: linear-gradient(\n    to right,\n    #0f0f0f 10%,\n    #2e2d2d,\n    #5f5e5e,\n    #b2b0b0,\n    #212121,\n    #090909\n  );\n}\n\n.opacity {\n  background-color: transparent;\n  opacity: 80%;\n}\n\n.gradient-text {\n  background: linear-gradient(\n    45deg,\n    #999 5%,\n    #fff 10%,\n    #ccc 30%,\n    #ddd 50%,\n    #ccc 70%,\n    #fff 80%,\n    #999 95%\n  );\n  background-clip: text;\n  color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
