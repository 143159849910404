// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
  background-color: #000000;
  box-shadow: 0 0 40px blanchedalmond;
}

.navbar-items {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.navbar-brand {
  color: #000000;
  text-shadow: 2px 2px 4px white;
}

.btn-transparent {
  background-color: transparent;
  border: none;
}

.btn:hover {
  background-color: #464646;
}

.btn-custom {
  outline: solid rgb(188, 183, 183) 1px;
  background-color: rgb(66, 64, 64);
}

.bi-person-circle,
.bi-person-fill-gear {
  color: blanchedalmond;
}

.bi-person-vcard {
  color: rgb(65, 65, 67);
}

.bi-person-vcard:hover {
  color: lightgoldenrodyellow;
  cursor: pointer;
}

.logout {
  color: rgb(107, 107, 110);
}

.ft-sm {
  font-size: 0.6rem;
}

.menu,
.menu:hover {
  background-color: transparent;
  border: none;
}

.menu:hover {
  transform: scale(150%);
}

.offcanvas.offcanvas-start {
  position: fixed;
  margin-top: 3.3rem;
  height: 25vh;
  width: 225px;
  border-bottom-right-radius: 10px;
  background-color: whitesmoke;
}

@media (max-width: 450px) {
  .offcanvas.offcanvas-start {
    width: 75%;
  }
}

@media (max-width: 375px) {
  .offcanvas.offcanvas-start {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/Header.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,mCAAmC;AACrC;;AAEA;EACE,aAAa;EACb,WAAW;EACX,8BAA8B;AAChC;;AAEA;EACE,cAAc;EACd,8BAA8B;AAChC;;AAEA;EACE,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,qCAAqC;EACrC,iCAAiC;AACnC;;AAEA;;EAEE,qBAAqB;AACvB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,2BAA2B;EAC3B,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;;EAEE,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,gCAAgC;EAChC,4BAA4B;AAC9B;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".navbar {\n  background-color: #000000;\n  box-shadow: 0 0 40px blanchedalmond;\n}\n\n.navbar-items {\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n}\n\n.navbar-brand {\n  color: #000000;\n  text-shadow: 2px 2px 4px white;\n}\n\n.btn-transparent {\n  background-color: transparent;\n  border: none;\n}\n\n.btn:hover {\n  background-color: #464646;\n}\n\n.btn-custom {\n  outline: solid rgb(188, 183, 183) 1px;\n  background-color: rgb(66, 64, 64);\n}\n\n.bi-person-circle,\n.bi-person-fill-gear {\n  color: blanchedalmond;\n}\n\n.bi-person-vcard {\n  color: rgb(65, 65, 67);\n}\n\n.bi-person-vcard:hover {\n  color: lightgoldenrodyellow;\n  cursor: pointer;\n}\n\n.logout {\n  color: rgb(107, 107, 110);\n}\n\n.ft-sm {\n  font-size: 0.6rem;\n}\n\n.menu,\n.menu:hover {\n  background-color: transparent;\n  border: none;\n}\n\n.menu:hover {\n  transform: scale(150%);\n}\n\n.offcanvas.offcanvas-start {\n  position: fixed;\n  margin-top: 3.3rem;\n  height: 25vh;\n  width: 225px;\n  border-bottom-right-radius: 10px;\n  background-color: whitesmoke;\n}\n\n@media (max-width: 450px) {\n  .offcanvas.offcanvas-start {\n    width: 75%;\n  }\n}\n\n@media (max-width: 375px) {\n  .offcanvas.offcanvas-start {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
