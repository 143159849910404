// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 85vh;
}

.modal-body {
  width: 20rem;
}

.modal-footer {
  height: 35px;
  padding: 0 10px;
}

.email {
  margin-bottom: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.m-tb {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.valid-feedback,
.invalid-feedback {
  font-size: 0.75rem;
}

.input::placeholder {
  font-style: italic;
  font-size: 0.8rem;
  color: rgb(142, 143, 144);
}

.nav-link {
  font-size: 0.8rem;
  color: burlywood;
}

.nav-link:hover {
  color: whitesmoke;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Login.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;;EAEE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".modal {\n  display: flex !important;\n  justify-content: center;\n  align-items: center;\n  height: 85vh;\n}\n\n.modal-body {\n  width: 20rem;\n}\n\n.modal-footer {\n  height: 35px;\n  padding: 0 10px;\n}\n\n.email {\n  margin-bottom: 0.25rem;\n}\n\n.ml-2 {\n  margin-left: 0.5rem;\n}\n\n.m-tb {\n  margin-top: 0.5rem;\n  margin-bottom: 0.5rem;\n}\n\n.valid-feedback,\n.invalid-feedback {\n  font-size: 0.75rem;\n}\n\n.input::placeholder {\n  font-style: italic;\n  font-size: 0.8rem;\n  color: rgb(142, 143, 144);\n}\n\n.nav-link {\n  font-size: 0.8rem;\n  color: burlywood;\n}\n\n.nav-link:hover {\n  color: whitesmoke;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
